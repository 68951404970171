import React, { useState, useEffect } from 'react';
import { signInWithRedirect, signOut } from 'aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';

import { userAppContext } from '../../Context/Context';

function validateEmail(email) {
  return /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9\-]+\.[a-zA-Z]{1,5}$/.test(email);
}

const registeredSsoProviders = { 'disgenet.com': 'entra-test' };

function Login() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { isAuthenticated, setIsAuthenticated, setAuthorization, setGroups, setUser } = userAppContext();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleChangeEmail = event => {
    const emailValue = event.target.value.trim();
    setEmail(emailValue);
  };

  const handleSso = async evt => {
    evt.preventDefault();
    try {
      setIsLoading(true); // Set loading to true when the button is clicked
      const domain = email.split('@')[1];
      if (Object.keys(registeredSsoProviders)?.includes(domain)) {
        // only allow registered domains to ssoLogin
        const ssoProvider = registeredSsoProviders[domain];
        signInWithRedirect({
          provider: { custom: ssoProvider },
        });
      } else {
        throw new Error('Unrecognized identity, please contact support@disgenet.com');
      }
    } catch (ex) {
      setErrorMessage(ex.message);
      // cleanup session
      setIsAuthenticated(false);
      setGroups([]);
      setAuthorization('');
      await signOut();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <link rel="canonical" href="/sso" />
      <div className={`container txt-contenido ${isAnimating ? 'fade-out' : ''}`}>
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading" />
          </div>
        )}
        <div className="login-container mb-5">
          <div className="signin-container">
            <div className="col-12">
              <button type="button" className="close" aria-label="Close">
                <Link style={{ color: 'white' }} to="/" aria-hidden="true">
                  ×
                </Link>
              </button>
              <h3 className="login-title">SSO Login</h3>
              <form className="validate-form" onSubmit={handleSso}>
                <div className="wrap-input100 validate-input" data-validate="Email is required">
                  <label className="label-input100" style={{ color: 'white' }}>
                    Email:
                  </label>
                  <input
                    className="input100"
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChangeEmail}
                    required
                  />
                </div>
                <div className="container-contact100-form-btn">
                  <div className="wrap-contact100-form-btn">
                    <div className="contact100-form-bgbtn" />
                    <button className="contact100-form-btn">Send</button>
                  </div>
                </div>
                {errorMessage ? <p className="text-center">{errorMessage}</p> : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
